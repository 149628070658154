import React, { useRef, createRef} from 'react';
import makeAnimated from 'react-select/animated';
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import CALENDAR from "../../../../assets/images/calendar.png"
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setImagePath } from '../../../../common/custom'
import Select from 'react-select';
import {  selectStyle } from '../../../../common/constants';
import { floatingWithTwoDecimal } from '../../../../common/custom';
import { getUserDetails } from '../../../../storage/user';

export const BookingPayment = (
    {
        paymentModalShow, setPaymentModalShow, name, datePayment, bookingPaymentsServiceMessage, 
        paymentItems, PaymentItemSetData, PaymentItemSetValue, handleBookingPayments, totalAmount, 
        getBookingPaymentStatus, currentPlan
    }
    ) => {
        const userData = getUserDetails();
        const datepickerRef = paymentItems.map(() => createRef());
        const showTimeSelection = (ind) => {
            setTimeout(function () { datepickerRef[ind].current.setOpen(true); }, 100);
        };
        const PaymentMethodTypeOption = [{ value: 'Online payment', label: 'Online payment' }, { value: 'Credit Card ', label: 'Credit Card ' }, { value: 'Cash', label: 'Cash' }, { value: 'Bank Check', label: 'Bank Check' }, { value: 'Square ', label: 'Square ' }, { value: 'Stripe', label: 'Stripe' }, { value: 'Venmo', label: 'Venmo' }, { value: 'PayPal', label: 'PayPal' }];
        
    return (
        <Modal show={paymentModalShow} onHide={() => setPaymentModalShow(false)} size="lg" className="" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h3>Payments: {name}</h3>
                                <span>Track all payments associated with this booking.</span>
                            </Modal.Title>
                            
                        </Modal.Header>
                        <Modal.Body>
                            {datePayment.totalErr ? <div className="errorCls errCommonCls  mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{datePayment.totalErr}</div> : ''}
                            {bookingPaymentsServiceMessage ? <div className="errorCls errCommonCls  mb-3"><img src={setImagePath(ERROR_ICON)} alt="" />{bookingPaymentsServiceMessage}</div> : ''}
                            <form>
                                <div className="row">
                                    <div className="form-group col-md-4 mb-0">
                                        <label className="pl-4 font-weight-bold">Paid on</label>
                                    </div>
                                    <div className="form-group col-md-4 mb-0">
                                        <label className="font-weight-bold">Payment Method</label>  
                                    </div>
                                    <div className="form-group col-md-4 pl-0 mb-0">
                                        <label className=" font-weight-bold">Amount</label> 
                                    </div>
                                </div>        
                                <div className="line-item--loop"> 
                                    {_.map(paymentItems, (val, ind) => {
                                    return <div className="line-item--row line-item--row2 pt-3 pb-2"  key={ind}>
                                        <div className="form-row">
                                                <div className="form-group col-md-4 mb-0">
                                                    <div className={"floating-label " + (val.payment_amount && !val.payment_date? datePayment.dateCls:'')}>
                                                        <DatePicker
                                                            name="date"
                                                            className={datePayment.dateCls ? "floating-input " + datePayment.dateCls : "floating-input"}
                                                            selected={val.payment_date}
                                                            onChange={(e) =>PaymentItemSetData(ind,e,"payment_date")}
                                                            maxDate={moment().toDate()}
                                                            placeholderText="Select Date"
                                                            ref={datepickerRef[ind]}
                                                            //disabled={currentPlan && currentPlan.plan_is_active === 1 && currentPlan.subscription_product_id !== 1 ? '' : 'disabled'}
                                                        />
                                                        <div onClick={() => showTimeSelection(ind)} className="input-calendar-icon"><img src={CALENDAR} alt="" width="20" height="20" /></div>
                                                        {val.payment_amount && !val.payment_date? datePayment.dateErr ? <span className="errorValidationMessage"> {datePayment.dateErr}</span> : '':''}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-4 mb-0"> 
                                                    <div className="floating-label " >
                                                        <Select
                                                            styles={selectStyle}
                                                            className="floating-select"
                                                            components={makeAnimated()}
                                                            isSearchable={false}
                                                            value={val.payment_method}
                                                            //defaultValue={PaymentMethodTypeOption[0]}
                                                            options={PaymentMethodTypeOption}
                                                            name="payment_method" 
                                                            //isDisabled={currentPlan && currentPlan.plan_is_active === 1 && currentPlan.subscription_product_id !== 1 ? '' : 'disabled'}
                                                            onChange={(e) =>PaymentItemSetData(ind,e,"payment_method")}   
                                                        />
                                                        {/* {taskState.taskDueTypeErr ? <span className="errorValidationMessage"> {taskState.taskDueTypeErr}</span> : ''} */}
                                                    </div>
                                                </div>
                                        
                                                <div className="form-group col-md-4 mb-0">
                                                    <div className={"floating-label " }>
                                                    <input  type="number" /* disabled={currentPlan && currentPlan.plan_is_active === 1 && currentPlan.subscription_product_id !== 1 ? '' : 'disabled'} */ placeholder="Enter Amount" name="payment_amount" min="0" value={val.payment_amount} 
                                                    onChange={(e) => PaymentItemSetValue(ind,e)} className="floating-input"  />
                                                    
                                                    </div>
                                                </div>     
                                        </div>
                                        {/* <div className="line-item-del">
                                            {ind !== 0 ?
                                                <a href="#phone" onClick={(e) => removePaymentItem(e, ind)} className="close-icn">
                                                <svg width="20px" height="20px" fill="var(--danger)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 174.239 174.239" style={{"enableBackground":"new 0 0 174.239 174.239"}} xmlSpace="preserve">
                                                    <g>
                                                    <path d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
                                                        c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
                                                        />
                                                    <path d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
                                                        s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
                                                        c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
                                                        c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"/>
                                                    </g>
                                                </svg>
                                                
                                                </a>
                                                :''}
                                        </div> */}
                                    </div>
                                        })}
                                </div>
                                {/* <div className="mb-4 mt-3">
                                    <a href="#addItm" onClick={(e)=>handleAddPaymentClick(e)} className="btn btn-secondary">Add Payment Item</a>
                                </div> */}

                                <div className="line-item--detail border-top">
                                    <div className="row justify-content-end">
                                    <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                                            <div className="total_row">                                
                                                <div className="row mr-3">
                                                <div className="col-5">
                                                    <div className="field-text text-right mb-0 mr-2">Total - </div>
                                                    
                                                </div>
                                                <div className="col-5">
                                                    <div className="d-flex align-items-center field-text">  
                                                    <p className="m-0 d-lg pr-1">{userData.planData.country_currency_symbol} </p>                            
                                                    <div className="feetotal-amount">{floatingWithTwoDecimal(totalAmount)}</div>
                                                    {/* <p className="m-0 pl-2 d-none d-lg-flex">USD</p> */}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        
                                    </div>
                                    </div>
                                </div>    
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            
                            <button type="button" className="btn btn-dark" onClick={() => setPaymentModalShow(false)}>Cancel</button>
                            
                            <button type="button"  onClick={() => handleBookingPayments()}  className="btn btn-primary">{'Save' }</button>
                        </Modal.Footer>
                    </Modal>
        
    );
};

