import React, { useEffect, useState } from "react";
import { Loader } from "../../../component/frontend/loader/loader";
import { Header } from "../../../component/frontend/header/header";
import { Link } from "react-router-dom"; // Removed withRouter as it's not needed for functional components in React Router v6
import { Footer } from '../../../component/frontend/footer/footer';
import Select from "react-select";
import { setImagePath } from "../../../../common/custom";
import { constants, selectStyle, baseUrl } from "../../../../common/constants";
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetail } from '../../../../duck/profile/profile.action';
import { publishBusinessForm } from "../../../../duck/businessForm/businessForm.action";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Swal from 'sweetalert2';
import ALERT_ICON from "../../../../assets/images/alert-icn.svg";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
// import { VIEW_PROFILE } from "../../../../routing/routeContants";
// import history from '../../../../../app/routing/history';

import { getUserDetails } from "../../../../storage/user";

const PreviewContact = ({ setPreview, formDetails, businessInfo, services, handleSaveDraft }) => {
  console.log('formDetails: in preview page', formDetails);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [allAddress, setAllAddress] = useState('-');
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

  const profileDetail = useSelector((state) => state.profile.getProfileDetailData.data);
  const viewBusinessFormData = useSelector((state) => state.businessForm.viewBusinessFormData);
  const userData = getUserDetails();
  const currentPlan = userData && userData.planData;

  useEffect(() => {
    setLoader(true);
    dispatch(getProfileDetail());
  }, [dispatch]);

  useEffect(() => {
    if (profileDetail) {
      const { organization } = profileDetail;
      const addressParts = [
        organization.street_address,
        organization.city,
        organization.state,
        organization.country,
        organization.zip
      ].filter(Boolean);

      setAllAddress(addressParts.join(", "));
    }
    setLoader(false);
  }, [profileDetail]);

  const handlePublishForm = async () => {
    if (currentPlan && currentPlan.plan_is_active === 0 && currentPlan.subscription_product_id === 1) {
      // Free trial expire then working is fine    
      let buttonMsg = currentPlan.subscription_product_id === 1 ? 'View Plans' : 'Renew Plan'
      let warMsg = currentPlan.subscription_product_id === 1 ? 'Free Trial Expired' : 'Subscription Expired'
      let msg = currentPlan.subscription_product_id === 1 ? 'Your free trial has expired. Please subscribe to a plan to access the application. ' : 'Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ';
      Swal.fire({
        title: warMsg,
        html: msg,
        showCancelButton: true,
        confirmButtonText: buttonMsg,
        cancelButtonText: 'Close',
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: 'cancel-alert-note',
      }).then((result) => {
        if (result.value) {
          setSubscriptionModalShow(true)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log('cancel')
        }
      })
    } else if (currentPlan && currentPlan.subscription_product_id === 3) {
      let buttonMsg = currentPlan.subscription_product_id === 3 ? 'Upgrade Plane' : 'View Plan';
      let warMsg = currentPlan.subscription_product_id === 3 ? 'Upgrade Plan' : 'Subscription Expired';
      let msg;
      if (currentPlan.subscription_product_id === 4) {
        msg = 'Your current plan is Gold. Please upgrade your plan to access the application. ';
      }
      Swal.fire({
        title: warMsg,
        html: msg,
        showCancelButton: true,
        confirmButtonText: buttonMsg,
        cancelButtonText: 'Close',
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: 'cancel-alert-note',
      }).then((result) => {
        if (result.value) {
          setSubscriptionModalShow(true)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log('cancel')
        }
      })
    } else {
      Swal.fire({
        html: "<h5><img src=" + setImagePath(ALERT_ICON) + "> Confirm Form Publish</h5><div className='mt-3'>" +
          "<p>Are you sure you want to publish the updates to your contact form? <strong>The changes will take effect immediately.</strong></p>" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: 'Publish Changes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: 'cancel-alert-note',
      }).then(async (result) => {
        if (result.value) {
          if (!viewBusinessFormData?.key) {
            handleSaveDraft(true);
            return;
          } else {
            handleSaveDraft(true);
          }
          try {
            setLoader(true); // Show loader during the publish action
            await dispatch(publishBusinessForm(null)); // Dispatch the publish action
            setLoader(false); // Hide loader on success
          } catch (error) {
            setLoader(false); // Hide loader on error
            console.error("Error publishing form:", error);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // If user cancels, no action is taken
          console.log('Publish cancelled');
        }
      });
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="main-site fixed--header lead-page-hdr unfixed-page-title">
        <Header />
        <main className="site-body">
          <section className="page-title addbasicquoteheader">
            <div className="container">
              <div className="row">
                <div className="col-lg-auto title--col">
                  <div>
                    <ol className="breadcrumb d-none d-lg-flex">
                      <li className="breadcrumb-item">
                        <Link to="">My Profile</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Contact Form Setup
                      </li>
                    </ol>
                    <h2 className="title">Preview Contact Form</h2>
                  </div>
                </div>
                <div className="col-auto ml-auto d-flex align-items-center title-elems">
                  <button className="btn btn-dark mr-15" onClick={() => setPreview(false)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.0001 19.92L8.48009 13.4C7.71009 12.63 7.71009 11.37 8.48009 10.6L15.0001 4.07996" stroke="#818186" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>Back</button>
                  <div className="btn-divider mr-15 d-none d-lg-flex"></div>
                  <button className="btn btn-secondary mr-15" onClick={() => handleSaveDraft()}>Save as Draft</button>
                  <button className="btn btn-primary" onClick={handlePublishForm} >Publish Contact Form</button>
                </div>
              </div>
            </div>
          </section>

          <section className="middle-section pt-0">
            <div className="container">
              {(profileDetail?.organization?.header_image && businessInfo.banner) && (
                <div className="row no-gutters-mbl mt-lg-4 mb-4">
                  <div className="col-lg-12">
                    <div className="main-card text-center">
                      <img
                        src={setImagePath(baseUrl[0] + profileDetail?.organization?.header_image)}
                        alt="Business Header"
                        className="img-fluid"
                        style={{ height: "225px" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex responsive-contact-form" style={{ gap: "50px" }}>
                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="business-info p-5" style={{ maxWidth: "380px", width: "380px" }}>
                          <div className="py-4 d-flex flex-column justify-content-between align-items-center w-100">
                            <div className="d-flex flex-column justify-content-center w-100" style={{ gap: '10px' }}>
                              {(businessInfo.logo) &&
                                <img
                                  src={setImagePath(baseUrl[0] + profileDetail?.organization?.company_logo)}
                                  alt="Company Logo"
                                  className="w-100"
                                />
                              }
                              {(businessInfo.name) &&
                                <h3 className="mt-4 business-name-style">{profileDetail?.organization?.name}</h3>
                              }
                            </div>
                            <div className="mt-3 w-100">
                              {(businessInfo.email) &&
                                <p className="title mb-2">{profileDetail?.organization?.email}</p>
                              }
                              {(businessInfo.phone) &&
                                <p className="title mb-2">{profileDetail?.organization?.phone}</p>
                              }
                              {(businessInfo.address) &&
                                <p className="title mb-2">{allAddress}</p>
                              }
                              {(businessInfo.licence) &&
                                <p className="title mb-2">{profileDetail?.organization?.licence}</p>
                              }
                              {(businessInfo.website) &&
                                <p className="title mb-2">
                                  {profileDetail?.organization?.website || 'www.google.com'}
                                </p>
                              }
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="contact-form-details">
                          <div className="card-header py-4">
                            <h2>Please fill out the form below, and we will get back to you as soon as possible.</h2>
                          </div>
                          <div className="card-body custom-card-body-form">
                            <div className="form-details-grid">
                              {formDetails.serviceInterested && (
                                <div className="row">
                                  <div className="form-group col-lg-5 col-md-5 mb-lg-4">
                                    <label className="form-label">Service Interested In  <span className="text-danger">*</span></label>
                                    <Select
                                      styles={selectStyle}
                                      className="floating-select"
                                      isSearchable={false}
                                      options={services.filter(service => service.visible)}
                                    // options={services}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row contact-form-custom-gaps">
                                {Object.entries(formDetails)
                                  .sort(([, a], [, b]) => parseInt(a.no) - parseInt(b.no)) // Sort by the 'no' property
                                  .map(([key, { label, required }]) => (
                                    key !== "serviceInterested" && (
                                      <div key={key} className="form-group col-lg-5 col-md-5 mb-lg-4">
                                        <label className="form-label">
                                          {label} {required && <span className="text-danger">*</span>}
                                        </label>
                                        {key !== "additionalInfo" ? (
                                          <input
                                            placeholder={label}
                                            type="text"
                                            name={key}
                                            className="form-control form-control-lg"
                                          />
                                        ) : (
                                          <textarea
                                            placeholder={label}
                                            name={key}
                                            className="form-control form-control-lg"
                                          />
                                        )}
                                      </div>
                                    )
                                  ))}
                              </div>
                              <div className="d-flex justify-content-center">
                                <button className="btn btn-primary">Submit</button>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </main>
        <Footer />
      </div>
      {/* Subscription Modal*/}
      <SubscriptionPlan loader={(data) => setLoader(data)}
        openSubscriptionModal={subscriptionModalShow}
        closeSubscriptionModal={() => setSubscriptionModalShow(false)}
        updatePlanDetail={(data) => { setSubscriptionModalShow(false); setLoader(false) }}
        currentPlan={currentPlan}
      />
    </>
  );
};

export const PreviewContactForm = withRouter(PreviewContact);