import React, { useEffect, useState } from "react";
import { Loader } from "../../../component/frontend/loader/loader";
// import { Header } from "../../../component/frontend/header/header";
// import { Link } from "react-router-dom"; // Removed withRouter as it's not needed for functional components in React Router v6
// import { Footer } from '../../../component/frontend/footer/footer';
import Select from "react-select";
import { setImagePath } from "../../../../common/custom";
import { selectStyle } from "../../../../common/constants"; //constants, baseUrl
import { useDispatch, useSelector } from 'react-redux';
// import { getProfileDetail } from '../../../../duck/profile/profile.action';
import { viewPublicForm, createBusinessContactForm } from "../../../../duck/businessForm/businessForm.action"; //publishBusinessForm,
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom.min";
// import axios from 'axios';

const BusinessFormComp = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [contactForm, setContactForm] = useState(null);
  // const [allAddress, setAllAddress] = useState('-');
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  // const viewBusinessFormData = useSelector((state) => state.businessForm.viewBusinessFormData);
  const viewPublicFormData = useSelector((state) => state.businessForm.viewPublicFormData);

  useEffect(() => {
    setLoader(true);
    dispatch(viewPublicForm(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (viewPublicFormData) {
      const parsedForm = viewPublicFormData?.contact_form ? JSON.parse(viewPublicFormData.contact_form) : null;
      setContactForm(parsedForm);
    }
  }, [viewPublicFormData]);

  // Handle the form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Reset errors when user starts typing
    setErrors({
      ...errors,
      [name]: ""
    });
  };

  // Handle the Select input change
  const handleSelectChange = (selectedOption, action) => {
    setFormData({
      ...formData,
      [action.name]: selectedOption.value
    });
    // Reset errors when user selects a value
    setErrors({
      ...errors,
      [action.name]: ""
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = Object.keys(contactForm?.contact_form || {}).filter(field => {
      return contactForm?.contact_form[field]?.required === true;
    });

    const missingFields = requiredFields.filter(field => !formData?.[field]);

    if (missingFields.length > 0) {
      const newErrors = {};
      missingFields.forEach(field => {
        newErrors[field] = "This field is required";
      });
      setErrors(newErrors);
      return;
    }
    console.log("prepareFormData:::", missingFields)

    const prepareFormData = {
      first_name: formData?.['firstName'] ?? null,
      last_name: formData?.['lastName'] ?? null,
      email: formData?.['email'] ?? null,
      address: formData?.['address'] ?? null,
      birthday: formData?.['birthday'] ?? null,
      address_line_1: formData?.['address1'] ?? null,
      address_line_2: formData?.['address2'] ?? null,
      city: formData?.['city'] ?? null,
      state: formData?.['state'] ?? null,
      zip: formData?.['zip'] ?? null,
      website: formData?.['website'] ?? null,
      other_1: formData?.['other1'] ?? null,
      service_type_id: formData?.['serviceInterested'] ?? null,
      title: formData?.['title'] ?? null,
      key: viewPublicFormData?.key ?? null
    };
    try {
      setLoader(true);
      await dispatch(createBusinessContactForm(prepareFormData));
      // setLoader(false);
      setFormData(() =>
        Object.keys(formData).reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {})
      );

    } catch (error) {
      setLoader(false);
      console.error("Error submitting form:", error);
    }
    finally {
      setLoader(false); // Always stop loader, even if there's an error
    }
  };

  return (
    <>
      {loader && <Loader />} {/* Display loader if it's true */}
      <div className="login_signup main-site">
        <main className="">
          <section className="middle-section pt-0">
            <div className="container">
              {contactForm?.business_details?.banner && (
                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card text-center w-100">
                      <img
                        src={setImagePath(contactForm?.business_details?.bannerVal)}
                        alt="Business Header"
                        className="img-fluid"
                        style={{ height: "225px" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex responsive-contact-form" style={{ gap: "50px" }}>

                {contactForm?.business_details && (
                  <div className="row no-gutters-mbl mt-lg-4">
                    <div className="col-lg-12">
                      <div className="main-card">
                        <div className="card w-100">
                          <section className="business-info" style={{ maxWidth: "380px", width: "380px" }}>
                            <div className="p-5 d-flex flex-column justify-content-between align-items-center w-100">
                              <div className="d-flex flex-column justify-content-center w-100" style={{ gap: '10px' }}>
                                <div>
                                  {contactForm?.business_details?.logo && (
                                    <img
                                      src={setImagePath(contactForm?.business_details?.logoVal)}
                                      alt="Company Logo"
                                      className="w-100"
                                    />
                                  )}
                                </div>
                                <h3 className="mt-4 business-name-style">{contactForm?.business_details?.nameVal}</h3>
                              </div>
                              <div className="mt-3 w-100">
                                {contactForm?.business_details?.email && (
                                  <p className="title mb-2">{contactForm?.business_details?.emailVal}</p>
                                )}
                                {contactForm?.business_details?.phone && (
                                  <p className="title mb-2">{contactForm?.business_details?.phoneVal}</p>
                                )}
                                {contactForm?.business_details?.address && (
                                  <p className="title mb-2">{contactForm?.business_details?.addressVal}</p>
                                )}
                                {contactForm?.business_details?.licence && (
                                  <p className="title mb-2">{contactForm?.business_details?.licenceVal}</p>
                                )}
                                {contactForm?.business_details?.website && (
                                  <p className="title mb-2">{contactForm?.business_details?.websiteVal || 'www.google.com'}</p>
                                )}
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row no-gutters-mbl mt-lg-4">
                  <div className="col-lg-12">
                    <div className="main-card">
                      <div className="card w-100">
                        <section className="contact-form-details">
                          <div className="card-header p-4 mt-3">
                            <h2>Please fill out the form below, and we will get back to you as soon as possible.</h2>
                          </div>
                          <div className="card-body p-4">
                            <div className="form-details-grid">
                              {contactForm?.contact_form?.serviceInterested && (
                                <div className="row">
                                  <div className="form-group col-lg-5 col-md-5 mb-lg-4">
                                    <label className="form-label">Service Interested In  <span className="text-danger">*</span></label>
                                    <Select
                                      styles={selectStyle}
                                      className="floating-select"
                                      isSearchable={false}
                                      options={contactForm?.services.filter(service => service.visible)}
                                      // options={contactForm?.services}
                                      onChange={handleSelectChange}
                                      name="serviceInterested"
                                    />
                                    {errors["serviceInterested"] && <span className="text-danger">{errors["serviceInterested"]}</span>}
                                  </div>
                                </div>
                              )}
                              <div className="row contact-form-custom-gaps">
                                {Object.entries(contactForm?.contact_form || {})
                                  .sort(([, a], [, b]) => parseInt(a.no) - parseInt(b.no))
                                  .map(([key, { label, required }]) => (
                                    key !== "serviceInterested" && (
                                      <div key={key} className="form-group col-lg-5 col-md-5 mb-lg-4">
                                        <label className="form-label">
                                          {label} {required && <span className="text-danger">*</span>}
                                        </label>
                                        {key !== "additionalInfo" ? (
                                          <input
                                            placeholder={label}
                                            type="text"
                                            name={key}
                                            className="form-control form-control-lg"
                                            value={formData[key] || ""}
                                            onChange={handleInputChange}
                                          />
                                        ) : (
                                          <textarea
                                            placeholder={label}
                                            name={key}
                                            className="form-control form-control-lg"
                                            value={formData[key] || ""}
                                            onChange={handleInputChange}
                                          />
                                        )}
                                        {errors[key] && <span className="text-danger">{errors[key]}</span>}
                                      </div>
                                    )
                                  ))}
                              </div>
                              <div className="d-flex justify-content-center">
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export const BusinessForm = withRouter(BusinessFormComp);